<template>
  <div class="col-lg-4 col-md-6 ml-auto mr-auto">
    <div class="card card-login">
      <div class="card-header ">
        <div class="card-header ">
          <h3 class="header text-center">
            <translate>Email verified</translate>
          </h3>
        </div>
      </div>
      <div class="card-body">
        <p class="text-center">
          {{
            $gettext(
              "Your email have been verified. Please click the button below to login to your account."
            )
          }}
        </p>
        <button
          @click.prevent="$router.push({ path: '/' })"
          class="btn pmd-ripple-effect btn-primary btn-block ml-0"
        >
          <translate>Login</translate>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { checkSecureLink } from "@/router/hooks";
import { mapActions, mapGetters } from "vuex";

export default {
  props: {
    selector: {
      type: String,
      required: true
    },
    token: {
      type: String,
      required: true
    }
  },
  created() {
    this.confirmEmail({
      selector: this.selector,
      token: this.token
    }).catch(() => {
      this.$router.push({
        name: "invalid-link",
        params: { selector: this.selector, token: this.token }
      });
    });
  },
  beforeRouteEnter(to, from, next) {
    checkSecureLink("email_confirmation", to, next);
  },
  computed: {
    ...mapGetters("me", ["isEmailVerified"])
  },
  methods: {
    ...mapActions("me", ["confirmEmail"])
  }
};
</script>
